<template>
    <CustomerIssuedCards />
  </template>
  <script>
    import CustomerIssuedCards from "@/components/Customers/issuedCards.vue";
    export default {
      components: {
        CustomerIssuedCards,
      },
    };
  </script>
  